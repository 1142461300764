import React, { useState } from "react";
import { Link } from "react-router-dom";

import FacebookIcon from "./icons/facebookIcon.jsx";
import InstagramIcon from "./icons/instagramIcon.jsx";

import HeaderNavItem from "./headerNavItem.jsx";
//padding on navbar items so they can be clicked

// flex center, first and last items having flex size of 1, mid item flex order 1 (in the middle)
//https://codepen.io/tutsplus/pen/bGVPVrY
function Header(props) {
  //show/unshow the dropdown
  const [toggle, setToggle] = useState(false);

  function toggleState() {
    setToggle(!toggle);
  }

  function classShow() {
    if (toggle) {
      return "mobileToggle";
    } else {
      return "";
    }
  }

  function hamburgerToggle() {
    if (toggle) {
      return "active";
    } else {
      return "";
    }
  }

  function Log(props) {
    return (
      <a href="https://hub.wemakewebsites.co.nz" onClick={toggleState}>
        <button
          className={"button buttonSolid " + (props.mobile && "mobileButton")}
        >
          Log In
        </button>
      </a>
    );
  }

  return (
    <nav id="home">
      <ul className="menu" style={props.system ? { height: "50px" } : null}>
        <li className="liMobileSpacer"></li>
        <li className="liLogo">
          <h2 className="logoText">LMPT</h2>
        </li>
        <li
          className={classShow() + " liNav"}
          style={props.system ? { top: "50px" } : null}
        >
          <HeaderNavItem
            section="/#home"
            text="Home"
            toggle={toggleState}
          ></HeaderNavItem>
          <HeaderNavItem
            section="/#testimonials"
            text="Testimonials"
            toggle={toggleState}
          ></HeaderNavItem>
          <HeaderNavItem
            section="/#services"
            text="Services"
            toggle={toggleState}
          ></HeaderNavItem>
          <HeaderNavItem
            section="/#contact"
            text="Contact"
            toggle={toggleState}
          ></HeaderNavItem>
          <Log mobile={true}></Log>
        </li>
        <li className="liSocial">
          <FacebookIcon class="headerIcon"></FacebookIcon>
          <InstagramIcon class="headerIcon"></InstagramIcon>
          <Log mobile={false}></Log>
        </li>
        <li className="menuIcon" onClick={toggleState}>
          <button type="button" className={"hamburger " + hamburgerToggle()}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Header;
