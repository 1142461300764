import React from "react";
import Fold from  "../components/fold.jsx"
import Services from  "../components/services.jsx"
import Testimonials from  "../components/testimonials.jsx"

function LandingPage() {
  return <div>
      <Fold></Fold>
      <Testimonials></Testimonials>
      <Services></Services>
  </div>;
}

export default LandingPage;