import React from "react";

function Fold() {
  return (
    <div>
      <div className="foldContainer">
        <div className="foldLeftContainer">
          <h1 className="foldHeading">We Are Finally Here!!</h1>
          <p className="foldText">
            Welcome to the beta testing for the long awaited and talked about app! Thank you for coming along and being apart of this journey!
          </p>
          <div className="foldButtonsContainer">
            {/* <button className="button buttonSolid foldButtonSpacer">Find Now</button> */}
            <button className="button buttonHollow" 
            onClick={(e) => {
            e.preventDefault();
            window.location.replace("/#contact");
          }}>Talk To Us</button>
          </div>
        </div>
        <div className="foldRightContainer horizontalCenter">
          <img className="foldImage" src="/foldImg.svg" alt="fold"></img>
        </div>
      </div>
      <div className="wedgeRight"></div>
    </div>
  );
}

export default Fold;
