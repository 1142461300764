import React from "react";
import ServicesCard from "./servicesCard";
import servicesData from "../data/servicesData.js";
import styles from "./servicesCard.module.css"

function Services() {
  return (
    <div>
      <div id="services" className={styles.servicesContainer}>
        {servicesData.map((data) => {
          return (
            <ServicesCard
              name={data.name}
              text={data.text}
              img={data.img}
              cssName={data.cssName}
              cssContainer={data.cssContainer}
              key={data.name}
            ></ServicesCard>
          );
        })}
      </div>
      <div className="wedgeRight"></div>
    </div>
  );
}

export default Services;
