import React from "react";

function FooterNavItem(props) {
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        window.location.replace(props.section);
      }}
    >
      <p className="footerNavigationItem">{props.text}</p>
    </div>
  );
}

export default FooterNavItem;
