import React from "react";

function FacebookIcon(props) {
  return (
    <svg
      className={props.class}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        id="Icon_awesome-facebook-square"
        data-name="Icon awesome-facebook-square"
        d="M28.571,2.25H3.429A3.429,3.429,0,0,0,0,5.679V30.821A3.429,3.429,0,0,0,3.429,34.25h9.8V23.371h-4.5V18.25h4.5v-3.9c0-4.439,2.643-6.891,6.691-6.891a27.262,27.262,0,0,1,3.966.346v4.357H21.655a2.561,2.561,0,0,0-2.887,2.766V18.25h4.913l-.786,5.121H18.768V34.25h9.8A3.429,3.429,0,0,0,32,30.821V5.679A3.429,3.429,0,0,0,28.571,2.25Z"
        transform="translate(0 -2.25)"
        fill="currentColor"
      />
    </svg>
  );
}

export default FacebookIcon;
