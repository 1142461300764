import React from "react";
import contact from "../data/contactInfo.js";
import FooterNavItem from "./footerNavItem.jsx";
import FacebookIcon from "./icons/facebookIcon.jsx";
import InstagramIcon from "./icons/instagramIcon.jsx";

function Footer() {
  return (
    <div id="contact" className="footerContainer">
      <div className="footerLeft verticalCenter">
        <p className="footerContact">Contact</p>
        <div className="footerContactGrid">
          {/* <p className="footerContactInfo">Ph:</p> */}
          <p className="footerContactInfo">{contact.phone}</p>
          {/* <p className="footerContactInfo">Email:</p> */}
          <p className="footerContactInfo">{contact.email}</p>
        </div>
        <div>
        <FacebookIcon class="footerIcon" ></FacebookIcon>
          <InstagramIcon class="footerIcon" ></InstagramIcon>
          {/* <img className="footerIcon" src={instagramIcon} alt="icon"></img>
          <img className="footerIcon" src={facebookIcon} alt="icon"></img> */}
        </div>
      </div>

      {/* <div className="footerMiddle verticalRowCenter" onClick={(e) => {
              e.preventDefault();
              window.location.replace("/#home");
            }}> */}
      {/* <div> */}
      {/* <div
            onClick={(e) => {
              e.preventDefault();
              window.location.replace("/#home");
            }}
          > */}
      {/* <p className="footerBackToTop">^</p>
            <p className="footerBackToTop">Back To Top</p>
            <p className="footerBackToTop">^</p> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}

      <div className="footerRight verticalCenter">
        <p className="footerNavigationTitle">Navigation</p>
        <FooterNavItem section="/#home" text="Home"></FooterNavItem>
        <div className="footerNavigationSpacer"></div>
        <FooterNavItem
          section="/#testimonials"
          text="Testimonials"
        ></FooterNavItem>
        <div className="footerNavigationSpacer"></div>
        <FooterNavItem section="/#Services" text="Services"></FooterNavItem>
      </div>
    </div>
  );
}

export default Footer;
