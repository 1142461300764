const services = [
    {
      name: "Programs",
      text: "Your needs are unique and a one-size-fits-all approach to coaching isn’t going to cut it. \n\n Get a comprehensive exercise programming with a tailored strategy that details the how and why to reach your goals.",
      img: "Icon-placeholder.png",
      cssName: "serviceNameOne",
      cssContainer: "serviceContainerOne"
    },
    {
      name: "Corrective",
      text: "Coming Soon...",
      img: "Icon-placeholder.png",
      cssName: "serviceNameTwo",
      cssContainer: "serviceContainerTwo"
    },
    {
      name: "1 on 1 PT",
      text: "Coming Soon...",
      img: "Icon-placeholder.png",
      cssName: "serviceNameThree",
      cssContainer: "serviceContainerThree"
    },
    {
     name: "Development",
     text: "Coming Soon....",
     img: "Icon-placeholder.png",
     cssName: "serviceNameFour",
     cssContainer: "serviceContainerFour"
    }
  ];
  
  export default services;
  