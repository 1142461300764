import React from "react";

function TestimonialCard(props) {

  //at 1390 wide it needs to switch to 1 card

  function inlineMove(){
    const width = document.documentElement.clientWidth;
    let slide = width*.7/props.view;
    if(props.view === 1){
      slide = width; //because padding is removed
    }
    const mv = (props.moved) * slide;
    const divStyle = {
      transition: "transform 1s",
      transform: "translateX(-" + mv +"px)"
    }
    return divStyle;
  }

  return (
    <div style={inlineMove()} className="testimonialCardContainer">
      <div className="testimonialBox">
        <p className="testiQuote">"{props.quote}"</p>
      </div>
      <div className="horizontalCenter">
        <div className="yellowSeperator"></div>
        <img
          className="testiImg"
          src={"/assets/images/" + props.img}
          alt="persons face"
        ></img>
      </div>
      <div className="horizontalCenter">
        <p className="testiName">{props.name}</p>
        <p className="testiType">{props.type}</p>
      </div>
    </div>
  );
}

export default TestimonialCard;
