import React from "react";

function InstagramIcon(props) {
  return (
    <svg
      className={props.class}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        id="Icon_awesome-instagram"
        data-name="Icon awesome-instagram"
        d="M16,10.033a8.2,8.2,0,1,0,8.2,8.2A8.19,8.19,0,0,0,16,10.033Zm0,13.538a5.334,5.334,0,1,1,5.333-5.334A5.343,5.343,0,0,1,16,23.572ZM26.45,9.7a1.913,1.913,0,1,1-1.913-1.914A1.909,1.909,0,0,1,26.45,9.7Zm5.433,1.942a9.471,9.471,0,0,0-2.584-6.7,9.529,9.529,0,0,0-6.7-2.585c-2.641-.15-10.558-.15-13.2,0a9.515,9.515,0,0,0-6.7,2.578,9.5,9.5,0,0,0-2.584,6.7c-.15,2.642-.15,10.561,0,13.2a9.471,9.471,0,0,0,2.584,6.7,9.541,9.541,0,0,0,6.7,2.585c2.641.15,10.558.15,13.2,0a9.467,9.467,0,0,0,6.7-2.585,9.534,9.534,0,0,0,2.584-6.7c.15-2.642.15-10.554,0-13.2ZM28.47,27.67a5.4,5.4,0,0,1-3.041,3.042c-2.106.835-7.1.643-9.43.643s-7.332.186-9.43-.643A5.4,5.4,0,0,1,3.527,27.67c-.835-2.106-.642-7.1-.642-9.433S2.7,10.9,3.527,8.805A5.4,5.4,0,0,1,6.568,5.763c2.106-.835,7.1-.643,9.43-.643s7.332-.186,9.43.643A5.4,5.4,0,0,1,28.47,8.805c.835,2.106.642,7.1.642,9.433S29.305,25.571,28.47,27.67Z"
        transform="translate(0.005 -2.238)"
        fill="currentColor"
      />
    </svg>
  );
}

export default InstagramIcon;
