const testies = [
  {
    name: "Helen Dyer",
    text: "Lachie has greatly improved my stature, physical and mental wellbeing. I would not hesitate to recommend Lachlan to anyone requiring his professional services. I have found Lachie to be outstanding with his knowledge, and understanding of all my personal needs.",
    type: "Client",
    img: "helen-dyer.jpeg"
  },
  {
    name: "Brooke Cole",
    text: "I’ve been training with Lachlan for 3 years now and we’ve gone from focusing on just getting moving the right way, to improving overall fitness and through to hitting some wedding goals. Challenging but enjoyable sessions. He even manages to sneak some education in along the way",
    type: "Client",
    img: "brooke-cole.jpg"
  },
  {
    name: "Zoe Klomp",
    text: "I’d been struggling with a back injury for years and nothing seemed to help. Lachie met me where I’m at, creating personalised workouts that have allowed me to get back into the sports that I love",
    type: "Client",
    img: "zoe-klomp.jpg"
  },
  {
    name: "Michael Burrows",
    text: "In the time I've worked with Lachlan he's not only built my confidence towards my body and helped me through my injuries he's helped me work towards and reach goals I once thought impossible and get me back to everyday life.",
    type: "Client",
    img: "michael-burrows.jpg"
  },
  {
    name: "Lisa McDaniel",
    text: "Lachie has helped me immensely after having major surgery, getting my body moving again and into the gym routine. Lachie helped me get back to feeling and moving the way I used to. Lachie is very knowledgeable and helps me with the best course of action for my individual needs. Would highly recommend him as a trainer.",
    type: "Client",
    img: "lisa-mcdaniel.jpeg"
  },
  {
    name: "Jacqui Batchellor",
    text: "I’m permanently in a wheelchair and Lachie has helped me regain confidence to get my body moving again.  I am now able to take a few small steps and I had little movement in my left arm and shoulder, but since working with Lachie this has improved considerably. Lachie challenges me to push myself and helps me recognise what I’m capable of achieving.",
    type: "client",
    img: "jacquie-batchellor.jpg"
  },
];

export default testies
