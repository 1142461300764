import React from "react";
import styles from "./servicesCard.module.css";

function ServicesCard(props) {
  return (
    <div className={styles.body}>
      <div className={styles.container}>
        <div className={styles.box}>
          <h2 className={styles.header}>{props.name}</h2>
          <div className={styles.imgBx}>
            <img src={"/" + props.img} alt="service icon" />
          </div>
          <div className={styles.content}>
            <div>
              <h2>{props.name}</h2>
              <p >{props.text}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesCard;
