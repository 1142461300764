import React from "react";

function HeaderNavItem(props) {
  return (
    <div
        className="navbarText hover"
        onClick={(e) => {
          e.preventDefault();
          window.location.replace(props.section);
          props.toggle();
        }}>
        {props.text}
      </div>
  );
}

export default HeaderNavItem;
