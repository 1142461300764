import React, { useState } from "react";
import TestimonialCard from "./testimonialCard";
import testimonialsData from "../data/testimonialsData.js";
import BackwardIcon from "./icons/backwardIcon";
// import styles from "./testimonials.module.css";

function Testimonials() {
  const [position, setPosition] = useState(0);

  const nbOfTesties = 5;
  const [view, setView] = useState(0);


  // function buttonStyle() {
  //   // const w = document.documentElement.clientWidth *0.05;
  //   const w = window.innerWidth * 0.05;
  //   return {
  //     width: w + "px",
  //     // width: "5%"
  //   };
  // }
  React.useEffect(() => {
    function handleResize() {
      const width = document.documentElement.clientWidth;
      if (width < 930) {
        setView(1);
      } else if (width < 1390) {
        setView(2);
      } else {
        setView(3);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  },[]);

  function btnMoveRight() {
    // console.log(position + " " + view +" "+nbOfTesties)
    if (position + view > nbOfTesties) {
      setPosition(0);
    } else {
      setPosition(position + view);
    }
  }

  function btnMoveLeft() {
    if (position - view < 0) {
      setPosition(nbOfTesties - view + 1);
    } else {
      setPosition(position - view);
    }
  }

  function gridStyle() {
    const divStyle = {
      gridTemplateColumns: ((100 / view).toString() + "% ").repeat(6),
    };

    return divStyle;
  }

  // React.useEffect(() =>{
  //    eslint-disable-next-line react-hooks/exhaustive-deps
  //   timer = setTimeout(() => btnMoveRight(), 10000);

  // });
  React.useEffect(() => {
    const interval = window.setInterval(() => {
      btnMoveRight();
    }, 15000);
    return () => window.clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position, view]);

  return (
    <div>
      <div className="testimonialContainer">
        <div
          id="testimonials"
          style={gridStyle()}
          className="testimonialsGridContainer"
        >
          {testimonialsData.map((data) => {
            return (
              <TestimonialCard
                quote={data.text}
                name={data.name}
                type={data.type}
                img={data.img}
                moved={position}
                view={view}
                key={data.name}
              ></TestimonialCard>
            );
          })}
        </div>

        <div className="verticalRowCenter">
          <button className="testiButtons" onClick={btnMoveLeft}>
            <BackwardIcon class="back" />
          </button>
          <button className="testiButtons" onClick={btnMoveRight}>
            <BackwardIcon class="forward" />
          </button>
        </div>
      </div>
      <div className="wedgeLeft"></div>
    </div>
  );
}

export default Testimonials;
